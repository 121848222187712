var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products"},[_c('Breadcrumb',{staticClass:"d-flex",attrs:{"items":_vm.breadcrumb}}),_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"position1"}}),_c('v-container',{class:{
        'd-none': _vm.$ebsn.meta(_vm.category, 'metaData.category_info.HIDE_TITLE')
      }},[(_vm.category)?_c('category-title',{attrs:{"category":_vm.category}}):_vm._e()],1),_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"product-list-swiper-container"},[(_vm.category.children.length > 1)?_c('div',{staticClass:"swiper-button-prev",attrs:{"id":"product-list-slider-prev-subcategories"}}):_vm._e(),_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.category.children),function(subCategory){return _c('swiper-slide',{key:subCategory.id},[_c('v-card',{staticClass:"proposal-card d-flex flex-column align-center",attrs:{"rounded":"sm","flat":"","to":{
                name: 'Category',
                params: { pathMatch: subCategory.slug }
              }}},[_c('v-img',{attrs:{"eager":"","src":subCategory.metaData.category_info.THUMB
                    ? subCategory.metaData.category_info.THUMB
                    : `${_vm.publicPath}imgs/no-image-grey.png`,"alt":subCategory.name,"width":"170","height":"170"}}),_c('v-card-text',{staticClass:"pre-slide-text align-center",domProps:{"innerHTML":_vm._s(subCategory.name)}})],1)],1)}),1),(_vm.category.children.length > 1)?_c('div',{staticClass:"swiper-button-next",attrs:{"id":"product-list-slider-next-subcategories"}}):_vm._e()],1)]),_c('v-container',{attrs:{"fluid":""}},[_c('ProductListSlider',{attrs:{"layout":25,"categoryId":_vm.category.categoryId,"fetchByCategory":true,"promo":true,"title":'In promozione in ' + _vm.category.name,"paginationClass":'swiper-pagination-promo',"showArrows":_vm.$vuetify.breakpoint.smAndUp}})],1),_c('div',{staticClass:"mt-4"},[_c('v-container',{attrs:{"fluid":""}},[_c('ProductListSlider',{attrs:{"layout":27,"categoryId":_vm.category.categoryId,"title":'I più venduti in ' + _vm.category.name,"paginationClass":'swiper-pagination-venduti',"showArrows":_vm.$vuetify.breakpoint.smAndUp}})],1)],1),_c('category-block',{staticClass:"category-block category-block-product1",attrs:{"target":_vm.category,"position":"product1"}}),_c('category-block',{staticClass:"category-block category-block-product2",attrs:{"target":_vm.category,"position":"product2"}}),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2"}}),(_vm.footerText)?_c('div',{staticClass:"white"},[_c('v-container',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.footerText)}})])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }